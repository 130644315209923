import isNull from 'lodash/isNull'
import { all, call, fork, select, takeLatest } from 'redux-saga/effects'

import drawShapeByType from './drawShapeByType'
import { handleToggleDrawableGroups2D } from './handleToggleDrawableGroups'
import { markupDocument, selectMarkupState } from './markupDocument'
import { DocumentMapping } from '../../../models/documentMapping'
import { IMaterialModificationConflict } from '../../../models/masterSetPlan'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { PROJECT_PAGE_TAB_VALUES } from '../../../shared/constants/project-settings-names'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Overlay, Pan, Workspace } from '../../lib/toolBoxes/2D/tools'
import addTooltipsToPath from '../../lib/utils/functionality-bindings/addTooltipsToPath'
import { selectOpacityDict, selectOverlayColor, setEstimatedOptionOverlayChunkId } from '../../slices/2D'
import { NormalizedDocumentChunk, selectDocumentMappings, selectNormalizedDocumentChunks } from '../../slices/documents'
import { GeometryGroup } from '../../slices/geometry'
import { selectSavedMaterialModifications } from '../../slices/masterSetPlan'
import { getActiveTab } from '../../slices/navigation'
import { ToolsState } from '../../slices/tools'
import { IMUP2DDrawableLocation, REGION_ENUMS } from '../../types'

interface DocumentMarkupStoreState {
    drawableLocations: IMUP2DDrawableLocation[]
    openingGroups: GeometryGroup[]
    areaOpacity: ToolsState['areaOpacityValue']
    lineOpacity: ToolsState['lineOpacityValue']
}

export function* handleInsertEstimatedOptionOverlay(action: ReturnType<typeof setEstimatedOptionOverlayChunkId>) {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (isNull(manager)) return

        // get markup state from redux store
        const { openingGroups, areaOpacity, lineOpacity, drawableLocations }: DocumentMarkupStoreState = yield select(
            selectMarkupState
        )

        // get tools from manager
        const [workspaceTool, overlayTool, panTool]: [Workspace, Overlay, Pan] = yield call(manager.getTools, [
            Workspace.NAME,
            Overlay.NAME,
            Pan.NAME,
        ])

        const activeTab: PROJECT_PAGE_TAB_VALUES = yield select(getActiveTab)

        if (overlayTool.isLocked || action.payload === -1) {
            yield call(overlayTool.resetOverlayRasters)
            yield call(overlayTool.resetSelectedImagesAndCrossHairs)
            yield call(overlayTool.lockOverlay, false)
            if (activeTab === PROJECT_PAGE_TAB_VALUES.DIGITIZER) {
                yield call(markupDocument)
            }

            if (action.payload === -1) return
        }

        const normalizedChunks: NormalizedDocumentChunk[] = yield select(selectNormalizedDocumentChunks)
        const chunkOpacityDict: Record<number, number> = yield select(selectOpacityDict)
        const possibleChunk: NormalizedDocumentChunk | undefined = normalizedChunks.find(
            (docChunk) => docChunk.id === action.payload
        )

        if (!possibleChunk) return

        const { src, id } = possibleChunk
        const chunkOpacity: number = isFinite(chunkOpacityDict[id])
            ? chunkOpacityDict[id] / 100
            : Overlay.DEFAULT_OPACITY

        const overlayColor = yield select(selectOverlayColor)

        yield call(overlayTool.insertOverlay, src, id, chunkOpacity, overlayColor)

        const documentMappings: DocumentMapping[] = yield select(selectDocumentMappings)
        const mapping = documentMappings.find((map) => map.document_chunk_id === action.payload)
        const overlay_center = mapping?.overlay_center

        if (!mapping || !overlay_center || !overlay_center.length) {
            yield call(overlayTool.resetOverlayRasters)
            yield call(overlayTool.resetSelectedImagesAndCrossHairs)
            throw new Error(`Either there is no document mapping associated with ${id}, or overlay_center was not set`)
        }

        yield call(overlayTool.setOverlayPostionCoordinates, id, overlay_center)

        const criteriaRegionPaths = workspaceTool.getItemsWithCriteria(
            'data',
            (data) => data.shapeType === REGION_ENUMS.TYPE
        ) as paper.Path[]

        /**
         * filter drawables belonging to active floor plan,
         * sort by drawing sequence order: area -> section -> point,
         * and create paths
         */
        const paths: paper.Path[] = yield all(
            drawableLocations
                .filter(
                    ({ document_chunk_id, drawing_type }) =>
                        document_chunk_id === id &&
                        drawing_type !== DRAWABLE_TYPES.NOTE &&
                        drawing_type !== DRAWABLE_TYPES.HIGHLIGHTER
                )
                // sorting locations that AREAS will be drawn first
                .sort((a, b) => {
                    if (a.shapeType === DRAWING_TYPES.AREA && b.shapeType !== DRAWING_TYPES.AREA) {
                        return -1
                    }
                    if (a.shapeType !== DRAWING_TYPES.AREA && b.shapeType === DRAWING_TYPES.AREA) {
                        return 1
                    }

                    return 0
                })
                .map((location) => call(drawShapeByType, location, areaOpacity, lineOpacity, criteriaRegionPaths))
        )

        // determine ids of hidden drawables groups
        const hiddenDrawableGroupIds: Set<number> = openingGroups.reduce((groupIds, { id, isActive }) => {
            if (Object.values(isActive).includes(false)) {
                groupIds.add(id)
            }

            return groupIds
        }, new Set<number>())

        // build visibility predicate with hidden drawable group ids
        const visibilityTest = (item: paper.Item): boolean =>
            !(hiddenDrawableGroupIds.has(item.data.opening_group_id) || isNonAreaJoistLine(item))

        // hide drawable groups per visibility test predicate
        yield call(workspaceTool.hideDrawablesWithCondition, visibilityTest)

        // select the saved material modifications for this project
        const savedMaterialModifications: IMaterialModificationConflict[] = yield select(
            selectSavedMaterialModifications
        )
        const matModIds = savedMaterialModifications.reduce((acc, matMod) => {
            if (action.payload && matMod.option_ids.includes(action.payload)) {
                acc.push(matMod.material_location_id)
            }

            return acc
        }, [] as number[])

        // get all workspace items that match the material modifications opening location ids
        const matModItems: paper.Path[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
            matModIds.includes(data.opening_location_id)
        )

        // sort material modifications into groups to be removed and to be adjusted
        const { matModsToHide, matModsToAdjust } = matModItems.reduce(
            (acc, item) => {
                const foundMatMod = savedMaterialModifications.find(
                    (mm) => mm.material_location_id === item.data.opening_location_id
                )

                if (foundMatMod) {
                    workspaceTool.compareCoordinates(item, foundMatMod.coordinates, foundMatMod.cutouts || [])
                        ? acc.matModsToHide.push(item)
                        : acc.matModsToAdjust.push(item)
                }

                return acc
            },
            { matModsToHide: [] as paper.Path[], matModsToAdjust: [] as paper.Path[] }
        )

        // remove items that were deleted in conflict resolution
        yield all(matModsToHide.map((item) => item.remove()))

        // adjust items that were adjusted in conflict resiolution
        yield all(
            matModsToAdjust.map((item) => {
                const foundMatMod = savedMaterialModifications.find(
                    (mm) => mm.material_location_id === item.data.opening_location_id
                )

                if (foundMatMod) {
                    workspaceTool.updateItemCoordinates(item, foundMatMod.coordinates, foundMatMod.cutouts || [])
                }
            })
        )

        // attach tooltips to paths
        yield all(paths.map((path) => call(addTooltipsToPath, path, workspaceTool)))

        // Hide all drawables that are inactive by default
        yield fork(handleToggleDrawableGroups2D)

        yield call(overlayTool.lockOverlay, true)

        yield call(panTool.activate)
    } catch (error) {
        yield call(console.error, error as any)
    }
}

export function* watchForSelectingNewEstimatedOptionOverlay() {
    yield takeLatest(setEstimatedOptionOverlayChunkId.type, handleInsertEstimatedOptionOverlay)
}
